<template>
  <svg
    viewBox="0 0 24 25"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10 2.5C9.20435 2.5 8.44129 2.81607 7.87868 3.37868C7.31607 3.94129 7 4.70435 7 5.5V6.5H4C2.34315 6.5 1 7.84315 1 9.5V19.5C1 21.1569 2.34315 22.5 4 22.5H20C21.6569 22.5 23 21.1569 23 19.5V9.5C23 7.84315 21.6569 6.5 20 6.5H17V5.5C17 4.70435 16.6839 3.94129 16.1213 3.37868C15.5587 2.81607 14.7956 2.5 14 2.5H10ZM15 6.5V5.5C15 5.23478 14.8946 4.98043 14.7071 4.79289C14.5196 4.60536 14.2652 4.5 14 4.5H10C9.73478 4.5 9.48043 4.60536 9.29289 4.79289C9.10536 4.98043 9 5.23478 9 5.5V6.5H15ZM9 8.5H15V20.5H9V8.5ZM7 8.5H4C3.44772 8.5 3 8.94772 3 9.5V19.5C3 20.0523 3.44772 20.5 4 20.5H7V8.5ZM17 20.5V8.5H20C20.5523 8.5 21 8.94772 21 9.5V19.5C21 20.0523 20.5523 20.5 20 20.5H17Z"
    />
  </svg>
</template>
